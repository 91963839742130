.content{
    padding: 20px;
    background: #fff;
    color: #000;
    border-radius: 30px;
    border: 10px solid #fa4029;
}
.content p{
    font-size: 20px;
}
.content .image{
    text-align: center;
}
.content .image-style-align-right{
    text-align: right;
}
.content .image-style-align-left{
    text-align: left;
}
.content img{
    max-width: 100%;
    height: auto;
}
.content table{
    width: 95%;
    border: 1px solid #fff;
    margin-left: auto;
    margin-right: auto;
}
.blogPostContent{
    max-width: 800px;
    margin-right: auto;
}
.content a{
    text-decoration: underline;
    color: #648dec;
}