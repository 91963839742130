body{
  margin: 0;
  margin-left: 5%;
  margin-right: 5%;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  background-color: #0d1117;
  color: #fff;
  height: 100%;
}
.container{
  width: 100%;
}
.h3{
  font-weight: 500;
}
.menu{
  width: 100%;
  margin: 20px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  margin-bottom: 15%;
}
.menu-nav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.menu-el{
  margin-left: auto;
  margin: 20px;
  color: #fff;
}
.menu-title{
  padding-top: 20px;
  font-weight: 600;
}
.menu-link:hover{
  color: #fa4028;
  transition: 0.3s;
}
.menu-link::after{
  content: "";
  width: 0;
  height: 2px;
  background: #fa5227;
  display: block;
  transition: 0.3s;
  border-radius: 10px;
}
.menu-link:hover::after{
  width: 100%;
  max-width: 200px;
  margin-right: auto;
  margin-left: auto;
}
a,
a:visited{
  text-decoration: none;
  color: #fff;
}
button{
  color: #fff;
  background: 0 0;
  border: 2px solid #fff;
  border-radius: 4px;
  width: 160px;
  height: 48px;
  text-align: center;
  padding: 10px 20px;
  position: relative;
  transition: 0.3s;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
}
@media only screen and (max-width:420px){
  button{
      width: 125px;
      margin: 4% 3px 0 4% !important;
      padding: 5px 10px !important;
  }
}
button::after{
  content: '';
  position: absolute;
  top: -8px;
  left: 6px;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: inherit;
  transition: 0.3s;
  background: #000;
  z-index: -1;
}
button.black-btn::after{
  background: #000;
  z-index: -1;
}
button:hover::after{
  transform: translate(-8px,6px);
  border-color: transparent;
}
.black-btn{
  color: #fff;
  background: #000;
}
.white-a{
  color: #fff !important;
}
.home button{
  margin-top: 50px;
  margin-right: 25px;
}
.btn {
  width: 80px;
  cursor: pointer;
  transform: scale(0.5);
  display: none;
  margin-right: auto;
  position: absolute;
  top: 25px;
  right: 20px;
}
@media only screen and (max-width:900px){
  .menu{
      display: block;
  }
  .menu-nav{
      flex-direction: column;
      text-align: center;
      display: none;
      margin-top: 80px;
      height: 100%;
      background-color: #0d1117;
      opacity: 0;
  }
  .menu-el{
      margin: 70px;
      font-size: 25px;
  }
  .btn{
      display: block;
  }
}
@media only screen and (min-width:900px){
  .menu-nav{
      flex-direction: row;
      display: flex !important;
      opacity: 1 !important;
  }
  .btn{
      display: none;
  }
}
span {
  display: block;
  width: 100%;
  border-radius: 3px;
  height: 8px;
  background: #fff;
  transition: all .3s;
  position: relative;
}
span + span {
  margin-top: 14px;
}
.active span:nth-child(1) {
  animation: ease .7s top forwards;
}
.not-active span:nth-child(1) {
  animation: ease .7s top-2 forwards;
}
.active span:nth-child(2) {
  animation: ease .7s scaled forwards;
}
.not-active span:nth-child(2) {
  animation: ease .7s scaled-2 forwards;
}
.active span:nth-child(3) {
  animation: ease .7s bottom forwards;
}
.not-active span:nth-child(3) {
  animation: ease .7s bottom-2 forwards;
}
@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 22px;
    transform: rotate(0);
  }
  100% {
    top: 22px;
    transform: rotate(45deg);
  }
}
@keyframes top-2 {
  0% {
    top: 22px;
    transform: rotate(45deg);
  }
  50% {
    top: 22px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}
@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 22px;
    transform: rotate(135deg);
  }
}
@keyframes bottom-2 {
  0% {
    bottom: 22px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}
@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.icon-btn{
  position: relative;
  top: 1px;
  margin-right: 8px;
}
.animated-border{
  position: absolute;
  top: 170px;
  right: 200px;
}
.animated-border-in{
  width: 555px;
  height: 555px;
  background-color: #FFE53B;
  background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%);    
  border-radius: 30% 70% 59% 41% / 30% 30% 70% 70%;
  box-shadow: -2vmin 1vmin 0 rgba(255, 255, 255, 0.07);
  animation: scale 0.8s ease-in-out, morph 3s linear infinite alternate;
}
.animated-border-in span{
  font-weight: bold;
  background: none;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;
  left: 10px;
}
@keyframes morph {
  0% {border-radius: 30% 70% 59% 41% / 30% 30% 70% 70%;}
  100% {border-radius: 87% 93% 100% 66% / 100% 68% 100% 76% }
}
@keyframes scale{
  from{
      transform: scale(0.3);
  }
  to{
      transform: scale(1);
  }
}
@media only screen and (max-width:1500px){
  .animated-border-in{
      width: 444px;
      height: 444px;
  }
  .animated-border-in span{
      top: 10px;
      left: 20px;
  }
  .logo-a-img{
      height: 400px;
  }
}
@media only screen and (max-width:1300px){
  .animated-border-in{
      width: 333px;
      height: 333px;
  }
  .animated-border{
      right: 100px;
  }
  .animated-border-in span{
      top: 10px;
      left: 20px;
  }
  .logo-a-img{
      height: 300px;
  }
}
.animated-border-mobile{
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  z-index: -5;
}
.animated-border-mobile-in{
  width: 250px;
  height: 130px;
  background-image: linear-gradient( 135deg, #fa4028 10%, #ff961b 100%);
  border-radius: 0% 46% 100% 0% / 100% 0% 100% 0%;
}

.abm2{
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -5;
  display: none;
}
@media only screen and (max-width:1100px){
  .animated-border{
      display: none;
  }
  .animated-border-mobile{
      display: block;
  }
  .abm2{
      display: block;
  }
  .menu-title{
      padding-top: 10px;
  }
  .menu-title a{
      color: #fff;
  }
  .copyright{
      color: #fff
  }
}
.abm2in{
  border-radius: 100% 0% 0% 100% / 100% 0% 100% 0%;
}
@media only screen and (max-width:500px){
  .animated-border-mobile-in{
      width: 230px;
      height: 110px;
  }
}

.skills-box{
  display: flex;
  flex-wrap: wrap;
}
.skill{
  color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 100px;
  font-size: 20px;
  margin: 5px;
  width: 140px;
  background-color: #0d1117;
  padding-bottom: 25px;
  box-shadow: -0.5vmin 0.5vmin 0 rgba(34, 24, 24, 0.6);
}
.skill svg{
  position: relative;
  top: 2px;
  margin-right: 1px;
}
.skills-category{
  background-color: #FFE53B;
  background-image: linear-gradient(147deg, #FFE53B, #FF2525 90%);  
  width: 350px;
  min-height: 200px;
  padding: 10px;
  border-radius: 20px;
  padding-left: 30px;
  transition: 0.3s;
  margin: 12px;
  color: #fff;
}
.skills-category:hover{
  border-radius: 0;
}
.skills-category-elms{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.boxes{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contact-box a{
  width: 400px;
}
.enterprise-box div{
  width: 300px;
}
.box{
  background-color: #FFE53B;
  background-image: linear-gradient(147deg, #FFE53B, #FF2525 90%);  
  width: 400px;
  height: 200px;
  padding: 10px;
  border-radius: 20px;
  padding-left: 30px;
  transition: 0.3s;
  margin: 20px;
  color: #fff;
}
.project-box{
  width: 320px;
}
.enterprise-option:hover,
.contact-option:hover,
.box:hover{
  box-shadow: 3px 3px 5px 0px rgb(121, 120, 124);
}
@media only screen and (max-width:1050px){
  .boxes{
      justify-content: center;
  }
  .skills-box{
    justify-content: center;
  }
}
@media only screen and (max-width:500px){
  .box{
      width: 90%;
      margin-left: auto;
      margin-right: auto;
  }
}
.enterprise-option,
.contact-option{
  background-color: #FFE53B;
  background-image: linear-gradient(147deg, #FFE53B, #FF2525 90%);  
  max-width: 300px;
  height: 200px;
  padding: 10px;
  border-radius: 20px;
  transition: 0.3s;
  margin: 20px;
  text-align: center;
  color: #fff;
}

.contact-icon{
  height: 35px;
  position: relative;
  top: 4px;
  margin-right: 5px;
  color: #fff;
}
::-webkit-scrollbar{
  width:10px
}
::-webkit-scrollbar-track{
  background:#c1c1c1
}
::-webkit-scrollbar-thumb{
  background:#666;
  transition:.2s;
  border-radius:5px
}
::-webkit-scrollbar-thumb:hover{
  background:#444
}
.menu-logo{
  height: 32px;
  position: relative;
  top: 7px;
  margin-right: 5px;
}
.copyright{
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.cnt{
  transition: 0.3s;
  margin-bottom: 40px;
}
.hide{
  animation: hide 0.5s ease-in-out;
  opacity: 0 !important;
}
@keyframes hide{
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}
.link-text{
  text-decoration: underline;
  text-decoration-thickness: 3px;

}
.link-text:hover{
  text-decoration-thickness: 4px;
}
.blog-box{
  width: 300px;
  height: 200px;
  overflow: hidden;
}
.dialog-box h1{
  word-wrap: break-word;
}